const Experience = () => {
    return (
        <div className="experience">
            <h2>Experience</h2>

            <ul>
                <li>
                    <i>RareSkills.io</i> - <strong>Solidity and Blockchain Engineering Fellowship</strong>, April 2023 - Present | Current
                    <ul>
                        <li>I will gain extensive experience in the following topics and workflows:</li>
                        <li>Building an ERC20 with custom attributes: Sanctions, God-mode (special account that can do anything), Token Sale, Partial refunds for token sales.</li>
                        <li>Bonding curve math and implementation.</li>
                        <li>Building NFT's using ERC721 and minting NFT's using ERC20.</li>
                        <li>Staking NFT's and receiving rewards in ERC20.</li>
                        <li>ERC1155</li>
                        <li>Testing and Linting: Solhint, Prettier, Slither, Echidna, Unit Tests, Coverage, Mutation.</li>
                        <li>Hacking and Security: Ethernaut, Capture the Ether, Damn Vulnerable DeFi, etc.</li>
                        <li>EVM and Yul programming.</li>
                        <li>Proxy Patterns: Diamond, Create2.</li>
                        <li>ECDSA signatures.</li>
                        <li>Vanity Addresses, multisigs, commit-reveal, Merkle Trees, efficient NFT airdrops, Multicalls.</li>
                    </ul>
                </li>
                <li>
                    <i>Zip Code Wilmington</i> - <strong>Adjunct Technical instructor</strong>, March 2023 - Present | Current
                    <ul>
                        <li>Provide technical asisstance for Software Developers in training</li>
                        <li>Review student’s code for correctness and provide constructive feedback</li>
                        <li>Give lectures on coding concepts and frameworks</li>
                    </ul>
                </li>
                <li>
                    <i>J.P. Morgan Chase</i> - <strong>Software Engineer</strong>, July 2019 - July 2021 | 2 years
                    <ul>
                        <li>Developed Spring Boot applications and micro services for financial infrastructure</li>
                        <li>Performed DevOps work to help migrate legacy components to JPMC’s internal Cloud</li>
                        <li>Debug production incidents and work with the team to fix issues</li>
                    </ul>
                </li>
                <li> 
                    <i>Barclay's</i> - <strong>Software Engineer</strong>, February 2018 - July 2019 | 1.5 years
                    <ul>
                        <li>Developed automated testing solutions using the Selenium and Cucumber frameworks</li>
                        <li>Ran weekly regression testing scenarios using automation to ensure release candiate quality</li>
                        <li>Debugged production incidents and work with the team to fix issues</li>
                    </ul>
                </li>
                <li>
                    <i>Zip Code Wilmington</i> - <strong>Student</strong>, 2017
                    <ul>
                        <li>Completed immersive training in object-oriented programming in an intensive 12-week coding academy</li>
                        <li>Built multiple projects from scratch, primarily in Java, using Test Driven Development</li>
                    </ul>
                </li>
                <li>
                    <i>Mount St.Mary's University</i> - <strong>Student</strong>, Graduated May 2016
                    <ul>
                        <li>Received a Bachelor of Science degree in Business Finance</li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

export default Experience;