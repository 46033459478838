// Import Assets
import profile from '../assets/profile.png';

const About = () => {
    return (
        <section className='about'>
            <h2>About Me</h2>
            <p>
            A software developer with a passion for continual learning and self development. 
            Always trying to leave people with a positive experience. 
            Looking to contribute knowledge and skills to help elevate a team as much as possible. 
            </p>
            <br></br>
            <p>
            First learned about Bitcoin in 2013, and has had an intense interest in blockchain technology ever since.
            Discovered Ethereum in 2017 shortly before starting a software development course.
            Nearly 5+ years of experience in traditional FinTech and 4+ years of heavy Dapp and blockchain usage.
            </p>
        </section>
    );
}

export default About;