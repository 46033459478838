// Import Assets
import uniswap from '../assets/uniswap.png';
import compound from '../assets/compound.png';
import aave from '../assets/aave.png';

const Projects = () => {
    return (
        <section className="projects">
            <h2>My Projects</h2>

            <div className="projects__cards">
                
                <div className="projects__card">
                    <h3>NFT Marketplace</h3>
                    <img src={aave} alt="NFT Marketplace" />
                    <p>
                        An example of an NFT Marketplace where you can create, buy, and sell NFT's
                    </p>

                    <a href="https://nft-market-place-zeta.vercel.app/" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/nft_market_place" target="_blank" className="button">Code</a>
                </div>

                <div className="projects__card">
                    <h3>Aave Flash Loan</h3>
                    <img src={uniswap} alt="Aave Flash Loan" />
                    <p>
                        An example of how to use an Aave Flash Loan.
                    </p>

                    <a href="" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/Aave_FlashLoan" target="_blank" className="button">Code</a>
                </div>

                <div className="projects__card">
                    <h3>Merkle Tree Air Drop</h3>
                    <img src={uniswap} alt="Merkle Tree Air Drop" />
                    <p>
                        An example of how to use a Merkle Tree to perform an airdrop.  This method is much more efficient than storing addresses in an array state variable.
                    </p>

                    <a href="" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/MerkleTree_Airdrop" target="_blank" className="button">Code</a>
                </div>

                <div className="projects__card">
                    <h3>Attack a contract with tx.origin</h3>
                    <img src={uniswap} alt="Attack a contract with tx.origin" />
                    <p>
                        An example of how tx.origin can be used to exploit a smart contract and why you should use msg.sender instead.
                    </p>

                    <a href="" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/Attack_with_txOrigin" target="_blank" className="button">Code</a>
                </div>

                <div className="projects__card">
                    <h3>Malicious External Contract</h3>
                    <img src={uniswap} alt="Malicious External Contract" />
                    <p>
                        An example of how a malicious external contract can attack your contract if called from within.
                    </p>

                    <a href="" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/malicious_external_contract" target="_blank" className="button">Code</a>
                </div>

                <div className="projects__card">
                    <h3>ERC20, ERC777, ERC1363 (in Progress)</h3>
                    <img src={uniswap} alt="ERC20, ERC777, ERC1363" />
                    <p>
                        This is my work for the RareSkills.io course.  This repo contains 4 different contracts and a README detailing the differences between ERC20, ERC777, and ERC1363 token standards. It is still in progress as of May 2nd, 2023.
                    </p>

                    <a href="" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/RareSkills_Week1" target="_blank" className="button">Code</a>
                </div>
                {/* <div className="projects__card">
                    <h3>Decentralized Market Place</h3>
                    <img src={compound} alt="Compound Landing Page" />
                    <p>A decentralized market place with 3 different types of users: Administrators, Vendors, and Customers. 
                        This personal project demonstrates my understanding of key Solidity concepts, design patterns, and security practices.
                    </p>

                    <a href="https://compound.finance/" target="_blank" className="button">Site</a>
                    <a href="https://github.com/Bmullzz/decentralized_market" target="_blank" className="button">Code</a>
                </div> */}

                {/* <div className="projects__card">
                    <h3>Arbitrage Bot</h3>
                    <img src={aave} alt="Arbitrage Bot" />
                    <p>
                    </p>

                    <a href="https://aave.com/" target="_blank" className="button">Site</a>
                    <a href="https://github.com/aave/aave-v3-core" target="_blank" className="button">Code</a>
                </div> */}

            </div>
        </section>
    );
}

export default Projects;